import _batchExecuteFunctions from "./batch-execute-functions";
var exports = {};
var batchExecuteFunctions = _batchExecuteFunctions;
function Destructor() {
  this._teardownRegistry = [];
  this._isTearingDown = false;
}
Destructor.prototype.registerFunctionForTeardown = function (fn) {
  if (typeof fn === "function") {
    this._teardownRegistry.push(fn);
  }
};
Destructor.prototype.teardown = function (callback) {
  if (this._isTearingDown) {
    callback(new Error("Destructor is already tearing down"));
    return;
  }
  this._isTearingDown = true;
  batchExecuteFunctions(this._teardownRegistry, function (err) {
    this._teardownRegistry = [];
    this._isTearingDown = false;
    if (typeof callback === "function") {
      callback(err);
    }
  }.bind(this));
};
exports = Destructor;
export default exports;
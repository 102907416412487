import _enumerate from "../../lib/enumerate";
import _errors from "./errors";
var exports = {};
var enumerate = _enumerate;
var errors = _errors;
var VERSION = "3.106.0";
var constants = {
  VERSION: VERSION,
  maxExpirationYearAge: 19,
  externalEvents: {
    FOCUS: "focus",
    BLUR: "blur",
    EMPTY: "empty",
    NOT_EMPTY: "notEmpty",
    VALIDITY_CHANGE: "validityChange",
    CARD_TYPE_CHANGE: "cardTypeChange"
  },
  defaultMaxLengths: {
    number: 19,
    postalCode: 8,
    expirationDate: 7,
    expirationMonth: 2,
    expirationYear: 4,
    cvv: 3
  },
  externalClasses: {
    FOCUSED: "braintree-hosted-fields-focused",
    INVALID: "braintree-hosted-fields-invalid",
    VALID: "braintree-hosted-fields-valid"
  },
  navigationDirections: {
    BACK: "before",
    FORWARD: "after"
  },
  defaultIFrameStyle: {
    border: "none",
    width: "100%",
    height: "100%",
    float: "left"
  },
  tokenizationErrorCodes: {
    81724: errors.HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE,
    // NEXT_MAJOR_VERSION this error triggers for both AVS and CVV errors
    // but the code name implies that it would only trigger for CVV verification
    // failures
    81736: errors.HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED
  },
  allowedStyles: ["-moz-appearance", "-moz-box-shadow", "-moz-osx-font-smoothing", "-moz-tap-highlight-color", "-moz-transition", "-webkit-appearance", "-webkit-box-shadow", "-webkit-font-smoothing", "-webkit-tap-highlight-color", "-webkit-transition", "appearance", "box-shadow", "color", "direction", "font", "font-family", "font-size", "font-size-adjust", "font-stretch", "font-style", "font-variant", "font-variant-alternates", "font-variant-caps", "font-variant-east-asian", "font-variant-ligatures", "font-variant-numeric", "font-weight", "letter-spacing", "line-height", "margin", "margin-top", "margin-right", "margin-bottom", "margin-left", "opacity", "outline", "padding", "padding-top", "padding-right", "padding-bottom", "padding-left", "text-align", "text-shadow", "transition"],
  allowedFields: {
    cardholderName: {
      name: "cardholder-name",
      label: "Cardholder Name"
    },
    number: {
      name: "credit-card-number",
      label: "Credit Card Number"
    },
    cvv: {
      name: "cvv",
      label: "CVV"
    },
    expirationDate: {
      name: "expiration",
      label: "Expiration Date"
    },
    expirationMonth: {
      name: "expiration-month",
      label: "Expiration Month"
    },
    expirationYear: {
      name: "expiration-year",
      label: "Expiration Year"
    },
    postalCode: {
      name: "postal-code",
      label: "Postal Code"
    }
  },
  allowedAttributes: {
    "aria-invalid": "boolean",
    "aria-required": "boolean",
    disabled: "boolean",
    placeholder: "string"
  },
  allowedBillingAddressFields: ["company", "countryCodeNumeric", "countryCodeAlpha2", "countryCodeAlpha3", "countryName", "extendedAddress", "locality", "region", "firstName", "lastName", "postalCode", "streetAddress"],
  allowedShippingAddressFields: ["company", "countryCodeNumeric", "countryCodeAlpha2", "countryCodeAlpha3", "countryName", "extendedAddress", "locality", "region", "firstName", "lastName", "postalCode", "streetAddress"],
  autocompleteMappings: {
    "cardholder-name": "cc-name",
    "credit-card-number": "cc-number",
    expiration: "cc-exp",
    "expiration-month": "cc-exp-month",
    "expiration-year": "cc-exp-year",
    cvv: "cc-csc",
    "postal-code": "billing postal-code"
  }
};
constants.events = enumerate(["ADD_CLASS", "AUTOFILL_DATA_AVAILABLE", "BIN_AVAILABLE", "CARD_FORM_ENTRY_HAS_BEGUN", "CLEAR_FIELD", "CONFIGURATION", "FRAME_READY", "INPUT_EVENT", "READY_FOR_CLIENT", "REMOVE_ATTRIBUTE", "REMOVE_CLASS", "REMOVE_FOCUS_INTERCEPTS", "SET_ATTRIBUTE", "SET_MESSAGE", "SET_MONTH_OPTIONS", "TOKENIZATION_REQUEST", "TRIGGER_FOCUS_CHANGE", "TRIGGER_INPUT_FOCUS", "VALIDATE_STRICT"], "hosted-fields:");
exports = constants;
export default exports;
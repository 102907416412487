import * as _isAndroid2 from "@braintree/browser-detection/is-android";
var _isAndroid = _isAndroid2;
try {
  if ("default" in _isAndroid2) _isAndroid = _isAndroid2.default;
} catch (e) {}
import * as _isChromeOs2 from "@braintree/browser-detection/is-chrome-os";
var _isChromeOs = _isChromeOs2;
try {
  if ("default" in _isChromeOs2) _isChromeOs = _isChromeOs2.default;
} catch (e) {}
import * as _isIos2 from "@braintree/browser-detection/is-ios";
var _isIos = _isIos2;
try {
  if ("default" in _isIos2) _isIos = _isIos2.default;
} catch (e) {}
import * as _isChrome2 from "@braintree/browser-detection/is-chrome";
var _isChrome = _isChrome2;
try {
  if ("default" in _isChrome2) _isChrome = _isChrome2.default;
} catch (e) {}
import * as _isFirefox2 from "@braintree/browser-detection/is-firefox";
var _isFirefox = _isFirefox2;
try {
  if ("default" in _isFirefox2) _isFirefox = _isFirefox2.default;
} catch (e) {}
import * as _isIosWebview2 from "@braintree/browser-detection/is-ios-webview";
var _isIosWebview = _isIosWebview2;
try {
  if ("default" in _isIosWebview2) _isIosWebview = _isIosWebview2.default;
} catch (e) {}
var exports = {};
var isAndroid = _isAndroid;
var isChromeOS = _isChromeOs;
var isIos = _isIos;
var isChrome = _isChrome;
function hasSoftwareKeyboard() {
  return isAndroid() || isChromeOS() || isIos();
}
function isChromeIos() {
  return isChrome() && isIos();
}
exports = {
  isAndroid: isAndroid,
  isChromeOS: isChromeOS,
  isChromeIos: isChromeIos,
  isFirefox: _isFirefox,
  isIos: isIos,
  isIosWebview: _isIosWebview,
  hasSoftwareKeyboard: hasSoftwareKeyboard
};
export default exports;
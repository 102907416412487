var exports = {};
function findParentTags(element, tag) {
  var parent = element.parentNode;
  var parents = [];
  while (parent != null) {
    if (parent.tagName != null && parent.tagName.toLowerCase() === tag) {
      parents.push(parent);
    }
    parent = parent.parentNode;
  }
  return parents;
}
exports = findParentTags;
export default exports;
import _once from "./once";
var exports = {};
var once = _once;
function call(fn, callback) {
  var isSync = fn.length === 0;
  if (isSync) {
    fn();
    callback(null);
  } else {
    fn(callback);
  }
}
exports = function (functions, cb) {
  var i;
  var length = functions.length;
  var remaining = length;
  var callback = once(cb);
  if (length === 0) {
    callback(null);
    return;
  }
  function finish(err) {
    if (err) {
      callback(err);
      return;
    }
    remaining -= 1;
    if (remaining === 0) {
      callback(null);
    }
  }
  for (i = 0; i < length; i++) {
    call(functions[i], finish);
  }
};
export default exports;